import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { Order } from '../../../lib/lib';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';
import { PrintReceiptEnum } from '../../../lib/printer/print-receipt-enum';
import { MatSlideToggleChange, MatSlideToggle } from '@angular/material/slide-toggle';
import { PrintReceiptTypeEnum } from '../../../lib/printer/print-receipt-type-enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'receipt-switcher',
  templateUrl: './receipt-switcher.component.html',
  styleUrls: ['./receipt-switcher.component.scss']
})
export class ReceiptSwitcherComponent implements OnInit, AfterViewInit, OnDestroy {
    order: Order;

    @Input()
    cssClass: string;

    @Input()
    negativeParticle = '';

    @Input()
    receiptType = PrintReceiptTypeEnum.OrderReceipt;

    @Output()
    Click: EventEmitter<any> = new EventEmitter<any>();

    private _langSubscription: Subscription;

    constructor(
        private translate: TranslateService,
        private configurationService: ConfigurationService,
        private dispatcherService: DispatcherService,
        saleService: SaleService,
    ) {
        this.order = saleService.order;
    }

    @ViewChild(MatSlideToggle, {static: false}) matSlideToggle: MatSlideToggle;

    ngAfterViewInit(): void {
      this.updateMatSlideToggleDataContent();
    }

    ngOnInit(): void {
        this._langSubscription = this.translate.onLangChange.subscribe(() => {
          this.updateMatSlideToggleDataContent();
        });
    }

    ngOnDestroy(): void {
      if (this._langSubscription) {
        this._langSubscription.unsubscribe();
        this._langSubscription = null;
      }
    }

    get slideToggleText(): string {
      if (this.receiptType === PrintReceiptTypeEnum.OrderReceipt){
        return 'Print Receipt';
      }

      return 'Card Terminal Receipt';
    }

    get isReceiptEnabled(): boolean {
      if (this.receiptType === PrintReceiptTypeEnum.OrderReceipt) {
        return this.order.isReceipt;
      }
      return this.order.isCardTerminalReceipt;
    }

    set isReceiptEnabled(value: boolean) {
      if (this.receiptType === PrintReceiptTypeEnum.OrderReceipt) {
        this.order.isReceipt = value;
      } else {
        this.order.isCardTerminalReceipt = value;
      }
      this.dispatcherService.onUserActivity();
    }

    get showReceiptButton(): boolean {
        if (
          !this.configurationService ||
          !this.configurationService.configuration ||
          !this.configurationService.configuration.printOrderReceipt) {
            return false;
          }

        return this.configurationService.configuration.printOrderReceipt === PrintReceiptEnum.UserChoice;
    }

    updateMatSlideToggleDataContent(): void {
      if (this.matSlideToggle === null || this.matSlideToggle === undefined) {
        return;
      }

      const matSlideToggleElementRef = this.matSlideToggle._elementRef;
      if (matSlideToggleElementRef === null || matSlideToggleElementRef === undefined) {
        return;
      }

      const matSlideToggleContent = matSlideToggleElementRef.nativeElement.querySelector('.mat-slide-toggle-content');
      if (matSlideToggleContent === null || matSlideToggleContent === undefined) {
        return;
      }

      if (this.negativeParticle != null && this.negativeParticle) {
      matSlideToggleContent.setAttribute('data-content', this.translate.instant(this.negativeParticle));
    }
}
}
