import { Injectable } from '@angular/core';

import { DisplayItemTypeEnum } from '../../../../../lib/display/display-item/display-item-type.enum';
import { BaseGridWorkflowService } from '../../base/base-grid-workflow.service';
import { Subscription } from 'rxjs';
import { SaleService } from '../../../../sale.service';
import { IVuHttp } from '../../../../vu/http/vu-http.interface';
import { DisplayItem } from '../../../../../lib/display/display-item/display-item';
import { Money, Product, OrderType } from '../../../../../lib/lib';

@Injectable()
export class CreateOrderGridWorkflowService extends BaseGridWorkflowService {

  subsrcription: Subscription;
  private saleService: SaleService;
  private vuHttp: IVuHttp;

  private basketJson: any;
  private basket: any;
  private multiBasket: any;
  private basketAmount: number;
  private productId: number;
  private currency: string;
  private odooContext: any;

  init(
  ) {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.vuHttp = this.vuCommunicationService.vuHttp;
  }

  get displayItemType(): DisplayItemTypeEnum {
    return DisplayItemTypeEnum.CreateOrder;
  }


  show(displayItem: DisplayItem, context: any): void {
    if (context
      && context.hasOwnProperty('odooContext')
      && context.odooContext.hasOwnProperty('basket_sale_order')) {
      if (!this.saleService.remoteTransaction.isOpened) {
        this._createOrderUseSaleOrder(context.odooContext.basket_sale_order);
      }
      return;
    }

    if (!context
      || !context.hasOwnProperty('odooContext')
      || !context.odooContext.hasOwnProperty('basket_json')
      || !context.odooContext.hasOwnProperty('basket_amount')
      || !context.odooContext.hasOwnProperty('basket_product_id')) {
      return;
    }

    this.parseContext(context);
    this.addCustomerDataToContext(context);

    if (!this.saleService.remoteTransaction.isOpened) {
      this.addProductToOrder();
    }
  }

  private _createOrderUseSaleOrder(
    saleOrder: {
      properties: any,
      order_lines: Array<{
        product_id: number,
        quantity: number,
        price: number,
        properties: any,
      }>
    }): void {
    if (!saleOrder) {
      throw new Error('CreateOrderGridWorkflowService. saleOrder is null');
    }

    if (!saleOrder.order_lines) {
      throw new Error('CreateOrderGridWorkflowService. saleOrder no order lines');
    }

    this.vuHttp.getProductsByIds(saleOrder.order_lines.map(line => line.product_id)).then((products: Product[]) => {
      this.saleService.resetOrder();
      for (const line of saleOrder.order_lines) {
        const product = products.find(item => item.id === line.product_id);
        if (!product) {
          continue;
        }

        const orderLine = this.saleService.order.addProduct(product, line.quantity, new Money(line.price, product.price.currencyCode));
        if (line.properties) {
          Object.entries(line.properties).forEach(
            ([key, value]) => orderLine.updateProperties(key, value)
          );
        }
      }

      this.saleService.order.type = OrderType.SaleOrder;
      if (saleOrder.properties) {
        Object.entries(saleOrder.properties).forEach(
          ([key, value]) => this.saleService.order.updateProperties(key, value)
        );
      }
    });
  }

  addProductToOrder(): void {
    const scope = this;
    scope.vuHttp.getProduct(this.productId).then(
      (result) => {
        const product = result;

        scope.saleService.resetOrder();
        let orderLine = null;
        if (this.multiBasket && this.multiBasket.items.length) {
          this.multiBasket.items.forEach(item => {
            const price = new Money(item.total, item.currency);
            orderLine = scope.saleService.order.addProduct(product, 1, price, null, null, true);
            orderLine.updateProperties('external_data', item);
          });
        } else {
          // todo log item for old mode ?
          const price = new Money(this.basketAmount, this.currency);
          orderLine = scope.saleService.order.addProduct(product, 1, price);
        }

        scope.saleService.order.updateProperties('order_json_data', this.basketJson);
        scope.saleService.order.updateProperties('odoo_context', this.odooContext);
      }
    );
  }

  addCustomerDataToContext(context: any): void {
    if (this.basket === undefined) {
      return;
    }
    const customer = this.basket.hasOwnProperty('customer') ? this.basket.customer : false;
    const firstName = customer && customer.hasOwnProperty('firstName') ? customer.firstName : '';
    const lastName = customer && customer.hasOwnProperty('lastName') ? customer.lastName : '';

    if (!context.odooContext.hasOwnProperty('externalContext')) {
      context.odooContext['externalContext'] = new Object();
    }

    Object.assign(context.odooContext.externalContext,
      {
        'firstName': firstName,
        'lastName': lastName,
      });
  }

  parseContext(context: any): void {
    this.odooContext = context.odooContext;
    this.basketJson = context.odooContext.basket_json;
    this.basket = JSON.parse(this.basketJson);
    this.multiBasket = context.odooContext.multi_basket;
    this.basketAmount = context.odooContext.basket_amount;
    this.productId = context.odooContext.basket_product_id;
    this.currency = this.basket.hasOwnProperty('currency') ? this.basket.currency : '';
  }
}
