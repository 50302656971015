import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LanguageService } from '../../../../services/language.service';
import { Locale } from '../../../../lib/lib';
import { BaseModalComponent } from '../../base/base-modal-component';

@Component({
  moduleId: module.id,
  selector: 'language-selection-popup',
  templateUrl: './language-selection-popup.component.html',
  styleUrls: ['./language-selection-popup.component.scss'],
})
export class LanguageSelectionPopUpComponent extends BaseModalComponent {

  flags = {};

  constructor(
    protected languageService: LanguageService
  ) {
    super();
    this.flags = languageService.flags;
  }

  get langs(): Locale[] {
    return this.languageService.avalibleLocalesForCustomer;
  }

  onClick(id: any) {
    this.close(id);
  }
}
