import { VuRole, DEFAULT_LANGUAGE_ID, PaymentMethod } from './lib';
import { BarcodeInputMethod } from './barcode-reader/barcode-input-method';
import { OneLineArticleSaleMode } from './one-line-article-sale-mode';
import { DisplayConfiguration } from './display/configuration/display-configuration';
import { DictionaryHelper } from './dictionary-helper';
import { PrintReceiptEnum } from './printer/print-receipt-enum';
import { Asset } from './assets/asset';
import { RfidCardPaymentDeviceType } from './payment/rfid-card-payment-device-type';
import { WorkflowPageType } from './workflow/workflow-page-type';
import { RefundPaymentActionEnum } from './payment/refund-payment-action-enum';
import { LostCardConfiguration } from '../modules/card-dispenser-restore-card/models/lost-card-configuration';

export class Configuration {
  isProduction = false;
  languageTimeout = 120000;
  timeIntervalBeforeTimeoutModal = 60000;
  externalApiRequestTimeoutMs = 5000;
  vuRole = VuRole.Entry;
  locale = DEFAULT_LANGUAGE_ID;
  showLanguageButton = false;
  customRoutes: any[];
  defaultShopTitle = '';
  showArticlesOnMainPage = false;
  printCardTerminalReceipt = PrintReceiptEnum.UserChoice;
  printOrderReceipt = PrintReceiptEnum.UserChoice;
  oneLineArticleSaleMode = OneLineArticleSaleMode.Disabled;
  receiptPrinterType = '';
  ticketPrinterType = '';
  barcodeInputMethod = BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard;
  private internalAdditionalProperties = {};
  private internalDictionaryHelper: DictionaryHelper;
  showDemoBackground = true;
  displayConfiguration: DisplayConfiguration = null;
  displayConfigurationId: number = null;
  customCss = '';
  customLogoId = -1;
  backgroundId: number = null;
  assets: Asset[] = null;
  showBasketButtonOnHeader = '';
  changeDate: Date;
  odooUrl = '';
  odooToken = '';
  shopUrl = '';
  maintenanceMessage = '';
  shopType = '';
  giftCardPaymentButtonType = '';
  cardDispenserAutoRetractTimeoutMs = 60000;
  moneyExchnageSupported = false;
  giftCardPartialPayment = true;
  giftCardSeparateButton = false;
  paymentMethods: PaymentMethod[];
  rfidCardPaymentDeviceType = RfidCardPaymentDeviceType.All;
  workflowPageType = WorkflowPageType.Simple;
  defaultPriceListId = 0;
  refundPaymentAction = RefundPaymentActionEnum.WithRefund;
  lostCardConfiguration: LostCardConfiguration;
  rfidCardUpgradeProduct = 0;

  private internalProperties: any;
  private internalPropertiesDictionaryHelper: DictionaryHelper;

  constructor() {
    this.internalDictionaryHelper = new DictionaryHelper(this.internalAdditionalProperties);
    this.internalPropertiesDictionaryHelper = new DictionaryHelper(this.internalProperties);
  }

  get additionalProperties(): any {
    return this.internalAdditionalProperties;
  }

  set additionalProperties(value) {
    this.internalAdditionalProperties = value;
    this.internalDictionaryHelper = new DictionaryHelper(this.internalAdditionalProperties);
  }

  get additionalPropertiesHelper(): DictionaryHelper {
    return this.internalDictionaryHelper;
  }

  get properties(): any {
    return this.internalProperties;
  }

  set properties(value) {
    this.internalProperties = value;
    this.internalPropertiesDictionaryHelper = new DictionaryHelper(this.internalProperties);
  }

  get propertiesHelper(): DictionaryHelper {
    return this.internalPropertiesDictionaryHelper;
  }

  get originalConfigurationHelper(): DictionaryHelper {
    if (!this.propertiesHelper) {
      return null;
    }
    return this.propertiesHelper.getPropertyHelper('configuration');
  }

  static fromOther(x: any): Configuration {
    const configuration = Object.assign(new Configuration(), x);

    if (x && x.assets) {
      configuration.assets = x.assets.map(item => Asset.fromAny(item));
    }

    if (x && x.changeDate) {
      configuration.changeDate = new Date(x.changeDate);
    }

    return configuration;
  }

  toString(): string {
    let m = `vuRole: ${this.vuRole} `;
    m += `languageTimeout: ${this.languageTimeout}. `;
    m += `locale: ${this.locale}. `;
    m += `isProduction: ${this.isProduction}. `;
    m += `timeIntervalBeforeTimeoutModal: ${this.timeIntervalBeforeTimeoutModal}. `;
    m += `showLanguageButton: ${this.showLanguageButton}. `;
    m += `defaultShopTitle: ${this.defaultShopTitle}. `;
    m += `showArticlesOnMainPage: ${this.showArticlesOnMainPage}. `;
    m += `printCardTerminalReceipt: ${this.printCardTerminalReceipt}. `;
    m += `printOrderReceipt: ${this.printOrderReceipt}. `;
    m += `refundPaymentAction: ${this.refundPaymentAction}. `;
    m += `oneLineArticleSaleMode: ${this.oneLineArticleSaleMode}. `;
    m += `barcodeInputMethod: ${this.barcodeInputMethod}. `;
    m += `receiptPrinterType: ${this.receiptPrinterType}. `;
    m += `ticketPrinterType: ${this.ticketPrinterType}. `;
    m += `additionalProperties: ${this.internalDictionaryHelper}. `;
    m += `displayConfiguration: ${this.displayConfiguration}. `;
    m += `displayConfigurationId: ${this.displayConfigurationId}. `;
    // m += `customCss: ${this.customCss}.`;
    m += `customLogoId: ${this.customLogoId}. `;
    m += `backgroundId: ${this.backgroundId}. `;
    m += `showBasketButtonOnHeader: ${this.showBasketButtonOnHeader}. `;
    m += `shopUrl: ${this.shopUrl}. `;
    m += `maintenanceMessage: ${this.maintenanceMessage}. `;
    m += `shopType: ${this.shopType}. `;
    m += `cardDispenserAutoRetractTimeoutMs: ${this.cardDispenserAutoRetractTimeoutMs}. `;
    m += `moneyExchnageSupported: ${this.moneyExchnageSupported}. `;
    m += `giftCardPartialPayment: ${this.giftCardPartialPayment}`;
    m += `giftCardSeparateButton: ${this.giftCardSeparateButton}`;
    m += `rfidCardPaymentDeviceType: ${this.rfidCardPaymentDeviceType}. `;
    m += `giftCardPaymentButtonType: ${this.giftCardPaymentButtonType}. `;
    m += `workflowPageType: ${this.workflowPageType}. `;
    m += `defaultPriceListId: ${this.defaultPriceListId}. `;
    m += `refundPaymentAction: ${this.refundPaymentAction}. `;
    m += `lostCardConfiguration: ${this.lostCardConfiguration}. `;
    m += `rfidCardUpgradeProduct: ${this.rfidCardUpgradeProduct}. `;

    return m;
  }
}

export class DtoVuConfiguration {
  vuRole = VuRole.Entry;
  locale = DEFAULT_LANGUAGE_ID;
  showArticlesOnMainPage = false;
  printCardTerminalReceipt = '';
  printOrderReceipt = '';
  oneLineArticleSaleMode = OneLineArticleSaleMode.Disabled;
  barcodeInputMethod = BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard;
  receiptPrinterType = '';
  ticketPrinterType = '';
  properties = {};
  additionalProperties = {};
  displayConfiguration: DisplayConfiguration = null;
  displayConfigurationId: number = null;
  customCss = '';
  customLogoId: number = null;
  backgroundId: number = null;
  assets: any;
  showBasketButtonOnHeader: any;
  changeDate: Date;
  odooUrl = '';
  odooToken = '';
  shopUrl = '';
  maintenanceMessage = '';
  shopType = '';
  giftCardPaymentButtonType = '';
  cardDispenserAutoRetractTimeoutMs = 60000;
  moneyExchnageSupported = false;
  giftCardPartialPayment = true;
  giftCardSeparateButton = false;
  paymentMethods: PaymentMethod[];
  rfidCardPaymentDeviceType = RfidCardPaymentDeviceType.All;
  workflowPageType = WorkflowPageType.Simple;
  defaultPriceListId = 0;
  refundPaymentAction = RefundPaymentActionEnum.WithRefund;
  lostCardConfiguration: LostCardConfiguration;
  rfidCardUpgradeProduct = 0;

  static fromOther(other: any): DtoVuConfiguration {
    const dtoVuConfiguration = Object.assign(new DtoVuConfiguration(), other);

    // const dtoVuConfiguration = new DtoVuConfiguration();
    dtoVuConfiguration.properties = other.properties;
    dtoVuConfiguration.additionalProperties = other.additionalProperties;

    if (!other.properties) {
      return dtoVuConfiguration;
    }

    const dictionaryHelper = new DictionaryHelper(other.properties);
    dtoVuConfiguration.vuRole = VuRole[dictionaryHelper.getString('vu_role')] || VuRole.Entry;
    dtoVuConfiguration.locale = dictionaryHelper.getString('locale', DEFAULT_LANGUAGE_ID);

    const configuration = dictionaryHelper.getProperty('configuration');
    const configurationDictionaryHelper = new DictionaryHelper(configuration);
    // dtoVuConfiguration.isReceiptPrintingEnabled = configurationDictionaryHelper.getBoolean('is_receipt_printing_enabled');
    dtoVuConfiguration.showArticlesOnMainPage = configurationDictionaryHelper.getBoolean('show_articles_on_main_page');
    // dtoVuConfiguration.forcePrintReceipt = configurationDictionaryHelper.getBoolean('force_print_receipt');
    dtoVuConfiguration.oneLineArticleSaleMode =
      OneLineArticleSaleMode[configurationDictionaryHelper.getString('one_line_article_sale_mode')]
      || OneLineArticleSaleMode.Disabled;
    dtoVuConfiguration.barcodeInputMethod =
      BarcodeInputMethod[configurationDictionaryHelper.getString('barcode_input_method')]
      || BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard;
    dtoVuConfiguration.receiptPrinterType = configurationDictionaryHelper.getString('receipt_printer_type');
    dtoVuConfiguration.ticketPrinterType = configurationDictionaryHelper.getString('ticket_printer_type');
    dtoVuConfiguration.changeDate = dictionaryHelper.getDate('configuration_write_date');
    dtoVuConfiguration.customCss = configurationDictionaryHelper.getString('custom_css');
    dtoVuConfiguration.displayConfigurationId = configurationDictionaryHelper.getString('display_configuration_id');
    dtoVuConfiguration.customLogoId = configurationDictionaryHelper.getString('image_id');
    dtoVuConfiguration.backgroundId = configurationDictionaryHelper.getString('background');
    dtoVuConfiguration.showBasketButtonOnHeader = configurationDictionaryHelper.getString('show_basket_button_on_header');
    dtoVuConfiguration.shopUrl = configurationDictionaryHelper.getString('shop_url');
    dtoVuConfiguration.refundPaymentAction = configurationDictionaryHelper.getString('refund_payment_action') as RefundPaymentActionEnum || RefundPaymentActionEnum.WithRefund;

    const locales = configurationDictionaryHelper.getString('locales');
    if (locales && other.additionalProperties) {
      other.additionalProperties['support_languages'] = locales;
    }

    dtoVuConfiguration.workflowPageType = configurationDictionaryHelper.getString('workflow_page_type');
    dtoVuConfiguration.defaultPriceListId = configurationDictionaryHelper.getNumber('default_pricelist_id');
    dtoVuConfiguration.lostCardConfiguration = LostCardConfiguration.createFromAny(configurationDictionaryHelper.getProperty('lost_card_info'));
    dtoVuConfiguration.rfidCardUpgradeProduct = configurationDictionaryHelper.getNumber('rfid_card_upgrade_product');

    return dtoVuConfiguration;
  }

  toString(): string {
    let m = `vuRole: ${this.vuRole} `;
    m += `locale: ${this.locale}. `;
    m += `showArticlesOnMainPage: ${this.showArticlesOnMainPage}. `;
    m += `printCardTerminalReceipt: ${this.printCardTerminalReceipt}. `;
    m += `printOrderReceipt: ${this.printOrderReceipt}. `;
    m += `refundPaymentAction: ${this.refundPaymentAction}. `;
    m += `oneLineArticleSaleMode: ${this.oneLineArticleSaleMode}. `;
    m += `barcodeInputMethod: ${this.barcodeInputMethod}. `;
    m += `receiptPrinterType: ${this.receiptPrinterType}. `;
    m += `ticketPrinterType: ${this.ticketPrinterType}. `;
    m += `additionalProperties: ${this.additionalProperties}. `;
    // m += `customCss: ${this.customCss ? this.customCss.length : 'None'}. `;
    m += `customLogoId: ${this.customLogoId}. `;
    m += `backgroundId: ${this.backgroundId}. `;
    m += `displayConfigurationId: ${this.displayConfigurationId}. `;
    m += `showBasketButtonOnHeader: ${this.showBasketButtonOnHeader}. `;
    m += `shopUrl: ${this.shopUrl}. `;
    m += `maintenanceMessage: ${this.maintenanceMessage}. `;
    m += `shopType: ${this.shopType}. `;
    m += `cardDispenserAutoRetractTimeoutMs: ${this.cardDispenserAutoRetractTimeoutMs}. `;
    m += `moneyExchnageSupported: ${this.moneyExchnageSupported}. `;
    m += `giftCardPartialPayment: ${this.giftCardPartialPayment}. `;
    m += `giftCardSeparateButton: ${this.giftCardSeparateButton}. `;
    m += `paymentMethods: ${this.paymentMethods}. `;
    m += `rfidPaymentCardDeviceType: ${this.rfidCardPaymentDeviceType}. `;
    m += `giftCardPaymentButtonType: ${this.giftCardPaymentButtonType}. `;
    m += `refundPaymentAction: ${this.refundPaymentAction}. `;
    m += `rfidCardUpgradeProduct: ${this.rfidCardUpgradeProduct}. `;
    return m;
  }
}
