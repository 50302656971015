import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Money, CashType, GlobalSettings, Order, PaymentSession, NonOperationalState, CreditCard } from '../../../lib/lib';
import { LoggingService } from '../../../services/logging/logging.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { SaleService } from '../../../services/sale.service';
import { NonOperationalService } from '../../../services/non-operational.service';
import { DemoPaymentService } from '../../../services/payment/demo-payment.service';
import { PaymentMessageStore } from '../../../lib/payment/payment-message-store';
import { PrintReceiptEnum } from '../../../lib/printer/print-receipt-enum';
import { PrintReceiptTypeEnum } from 'src/app/lib/printer/print-receipt-type-enum';
import { GiftCardPaymentButtonType } from 'src/app/lib/gift-card/gift-card-payment-button-type';
import { Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'payment-gift-card',
  templateUrl: 'payment-gift-card.component.html',
  styleUrls: ['payment-gift-card.component.scss']
})
export class PaymentGiftCardComponent implements OnInit, OnDestroy {

  @Input()
  hideBackButton: boolean;

  order: Order;
  paymentSession: PaymentSession;
  canPayWithBluecode = true;
  nonOperationalSubscription: Subscription;
  cashlessPaymentMessageStore: PaymentMessageStore;
  isProduction = true;
  public printReceiptTypeEnums = PrintReceiptTypeEnum;
  private isBackButtonVisible: boolean;
  pageHeaderText: string;

  constructor(
    private log: LoggingService,
    public configurationService: ConfigurationService,
    private dispatcherService: DispatcherService,
    private saleService: SaleService,
    private nonOperationalService: NonOperationalService,
    private demoPaymentService: DemoPaymentService,
  ) {
    this.order = saleService.order;
    this.cashlessPaymentMessageStore = this.saleService.cashlessPaymentMessageStore;
    this.paymentSession = saleService.paymentSession;
    this.isProduction = configurationService.configuration.isProduction;
  }

  ngOnInit(): void {
    this.isBackButtonVisible = this.dispatcherService.isBackButtonVisible;
    this.dispatcherService.isBackButtonVisible = !this.hideBackButton;
    this.pageHeaderText = this.getPageHeaderText();
    this.dispatcherService.pageHeaderSet(this.pageHeaderText);
    this.dispatcherService.backButtonTextSet('Cancel');
    this.nonOperationalSubscription = this.nonOperationalService
      .eventStateChanged
      .subscribe((x: NonOperationalState) => this.canPayWithBluecode = !x.isNonOperational);
  }

  ngOnDestroy(): void {
    this.dispatcherService.isBackButtonVisible = this.isBackButtonVisible;
    this.dispatcherService.pageHeaderSet();
    this.dispatcherService.backButtonTextReset();
    this.nonOperationalSubscription.unsubscribe();
  }

  get currencyCode(): string {
    return GlobalSettings.getCurrencyCode();
  }

  get amountToPay(): string {
    return this.paymentSession ? this.paymentSession.amountToPay.toStringCurrencySign() : 'ERROR';
  }

  onCardClick(event: any): void {
    this.demoPaymentService.onDenominationClick();
  }

  get cssClassMessage(): string {
    return this.cashlessPaymentMessageStore && this.cashlessPaymentMessageStore.higlightMessage ? 'message blinking' : 'message';
  }


  onPrintReceiptChange(event: any): void {
    this.order.isReceipt = event.currentValue;
    this.dispatcherService.onUserActivity();
  }

  get showReceiptButton(): boolean {
    if (!this.configurationService || !this.configurationService.configuration
            || !this.configurationService.configuration.printOrderReceipt) {
        return false;
      }

    return this.configurationService.configuration.printOrderReceipt === PrintReceiptEnum.UserChoice;
  }

  get receiptSwitcherStyle(): string {
    return 'left-mode-receipt-switcher';
  }

  getPageHeaderText(): string {

    if (this.configurationService.configuration.giftCardPaymentButtonType === GiftCardPaymentButtonType.NfcCard) {
      return 'Payment by RFID Card';
    }
    return 'Gift card payment';
  }

}
