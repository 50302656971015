import { WorkflowStepType, Order, Ticket } from '../../lib/lib';
import { MachineBaseService } from './machine-base.service';
import {
  Money,
  PaymentSession,
  OrderSaveResult,
  WorkflowStepState,
  TeaserType,
  PrintTaskType,
  PrintTask,
  PrintTaskResult,
  MachineInactivitySettings,
  PaymentMethod,
} from '../../lib/lib';
import { SaleService } from '../sale.service';
import { MessageType, Message } from '../message.service';
import { CardDispenserActionResult } from '../../lib/rfid-card/card-dispenser-action-result';
import { PostSaleTypeEnum } from '../../lib/post-sale-step';
import { CardDispenserService } from '../card-dispenser/card-dispenser.service';
import { CardDispenserCompleteActionResult } from '../../lib/rfid-card/card-dispenser-complete-action-result';
import { Injectable } from '@angular/core';
import { VuAutomaticActionType } from 'src/app/lib/ticket/ticket-vu-automatic-action-type';
import { OpenGateTypeEnum } from 'src/app/lib/gate/open-gate-type.enum';
import { ModalService } from '../gui/modal/modal-service';
import { TimeoutSimpleModalComponent } from 'src/app/components/modal/timeout-simple-modal/timeout-simple-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RfidCardData } from 'src/app/lib/card-dispenser/rfid-card-data';
import { MessageModalService } from 'src/app/modules/message-modal/services/message-modal.service';
import { WorkflowPageType } from 'src/app/lib/workflow/workflow-page-type';
import { TranslateService } from '@ngx-translate/core';
import { RefundPaymentActionEnum } from 'src/app/lib/payment/refund-payment-action-enum';

@Injectable()
export class MachinePostSaleService extends MachineBaseService {
  private saleService: SaleService;
  private paymentSession: PaymentSession;
  private cardDispenserService: CardDispenserService;
  private modalService: ModalService;
  private messageModalService: MessageModalService;
  private isCashlessPayment = false;
  private modalRef: BsModalRef;
  private gateSubscription: Subscription;
  isBackToHomeAfterSale: boolean;
  disableNavigation = false;
  externalPayment = false;
  private translateService: TranslateService;

  private writeCardDataCompleted: boolean;

  private createdTicket: Ticket;
  private rfidCardData: RfidCardData;
  private completeMessages: string[];
  private postSaleStepIndex = 0;
  private postSaleSteps = [
    PostSaleTypeEnum.PrintCreditCardReceipt,
    PostSaleTypeEnum.WaitingForMoney,
    PostSaleTypeEnum.ProducingRfidCard,
    PostSaleTypeEnum.ReadRfidCard,
    PostSaleTypeEnum.SaveOrder,
    PostSaleTypeEnum.PrintTickets,
    PostSaleTypeEnum.PrintReceipt,
    PostSaleTypeEnum.CheckExitRole,
    PostSaleTypeEnum.OpenGate,
    PostSaleTypeEnum.WriteRfidCard,
    PostSaleTypeEnum.PrintRFIDCardReceipt,
    PostSaleTypeEnum.ReleasingRfidCard,
    PostSaleTypeEnum.WaitingForRfidCardRemoval,
    PostSaleTypeEnum.Thanks,
  ];

  init(): void {
    super.init();
    this.saleService = this.injector.get(SaleService);
    this.saleService.eventMoneyChanged.subscribe((x: Money) => this.onMoneyChanged(x));
    this.vuConnection.eventReturnChangeFinished.subscribe(() => this.onReturnChangeFinished());
    this.vuConnection.eventOrderSaveResultReceived.subscribe((x: OrderSaveResult) => this.onOrderSaveResult(x));
    this.vuConnection.eventResetOrderRfidCardAccessDataResultReceived
      .subscribe((x: OrderSaveResult) => this.onResetOrderRfidCardAccessDataResult(x));
    this.vuConnection.eventPrintTaskResultReceived.subscribe((x: PrintTaskResult) => this.onPrintTaskResultReceived(x));
    this.vuConnection.eventCashlessEvent.subscribe(
      (x: boolean) => this.onEventCashlessEvent(x)
    );
    this.cardDispenserService = this.injector.get(CardDispenserService);
    this.cardDispenserService.cardRead.subscribe((x: CardDispenserActionResult) => this.onRfidCardRead(x));
    this.cardDispenserService.cardReadyToTake.subscribe((x: CardDispenserActionResult) => this.onRfidCardReadyToTake(x));
    this.cardDispenserService.complete.subscribe((x: CardDispenserCompleteActionResult) => this.onRfidCardComplete(x));
    this.cardDispenserService.readCardDataCompleted.subscribe((x: CardDispenserCompleteActionResult) => this.onReadCardDataCompleted(x));
    this.cardDispenserService.writeCardDataCompleted.subscribe((x: CardDispenserCompleteActionResult) => this.onWriteCardDataCompleted(x));
    this.modalService = this.injector.get(ModalService);
    this.messageModalService = this.injector.get(MessageModalService);
    this.translateService = this.injector.get(TranslateService);
  }

  get machineName(): string {
    return 'Post Sale Machine';
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toFlowBegin', from: ['off'], to: 'flowBegin' },
      { name: 'toPrintCreditCardReceipt', from: ['flowBegin'], to: 'printCreditCardReceipt' },
      { name: 'toWaitingForMoney', from: ['flowBegin'], to: 'waitingForMoney' },
      { name: 'toWaitingForMoneyTimeout', from: ['waitingForMoney'], to: 'waitingForMoneyTimeout' },
      { name: 'toCancelled', from: ['waitingForMoneyTimeout'], to: 'cancelled' },
      {
        name: 'toReturningAmountPaid', from: [
          'cancelled',
          'returningAmountChange',
          'saveOrder',
          'printTickets',
          'producingRfidCard',
          'readRfidCard',
          'releasingRfidCard',
          'waitingForRfidCardRemoval',
          'gateReadBarcode',
          'printReceipt',
        ], to: 'returningAmountPaid'
      },
      { name: 'toRevertingCashlessTransaction', from: ['returningAmountPaid'], to: 'revertingCashlessTransaction' },
      { name: 'toCheckForChange', from: ['waitingForMoneyTimeout'], to: 'checkForChange' },
      { name: 'toReturningAmountChange', from: ['checkForChange'], to: 'returningAmountChange' },
      {
        name: 'toSaveOrder', from: [
          'checkForChange',
          'returningAmountChange',
          'printCreditCardReceipt',
          'producingRfidCard',
          'readRfidCard',
          'flowBegin'
        ], to: 'saveOrder'
      },
      { name: 'toPrintTickets', from: ['saveOrder'], to: 'printTickets' },
      { name: 'toPrintReceipt', from: ['saveOrder', 'printTickets'], to: 'printReceipt' },
      { name: 'toGateReadBarcode', from: ['saveOrder', 'printTickets', 'printReceipt'], to: 'gateReadBarcode' },
      {
        name: 'toProducingRfidCard', from: [
          'flowBegin',
          'printCreditCardReceipt',
          'checkForChange',
          'returningAmountChange'], to: 'producingRfidCard'
      },
      { name: 'toReadRfidCard', from: [
        'producingRfidCard',
        'flowBegin',
        'printCreditCardReceipt',
        'checkForChange',
        'returningAmountChange'], to: 'readRfidCard' },
      { name: 'toWriteRfidCard', from: ['saveOrder', 'printTickets', 'printReceipt'], to: 'writeRfidCard' },
      { name: 'toPrintRFIDCardReceipt', from: ['saveOrder', 'printTickets', 'printReceipt', 'writeRfidCard'], to: 'printRFIDCardReceipt' },
      { name: 'toReleasingRfidCard', from: ['saveOrder', 'printTickets', 'printReceipt', 'writeRfidCard', 'printRFIDCardReceipt'], to: 'releasingRfidCard' },
      { name: 'toWaitingForRfidCardRemoval', from: ['releasingRfidCard'], to: 'waitingForRfidCardRemoval' },
      { name: 'toFlowEnd', from: ['*'], to: 'flowEnd' },
      { name: 'toThanks', from: ['*'], to: 'thanks' },
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff(event: any, isHardReset: false): void {
        scope.paymentSession = null;
        scope.disableNavigation = false;
        scope.dispatcherService.isBackButtonEnabled = true;
        scope.dispatcherService.workflowReset('', WorkflowStepType.None);
        if (scope.modalRef) {
          scope.modalService.close(scope.modalRef);
          scope.modalRef = null;
        }
        scope.writeCardDataCompleted = false;
        scope.rfidCardData = null;
        scope.completeMessages = null;
      },
      onToFlowBegin(): void {
        scope.resetStepsData();
        if (!scope.disableNavigation) {
          scope.isBackToHomeAfterSale = false;
          scope.dispatcherService.isBackButtonEnabled = false;
        }
        scope.paymentSession = scope.saleService.paymentSession;
        scope.isCashlessPayment = scope.saleService.order.paymentMethod !== PaymentMethod.Cash;
        const workflowName = scope.paymentSession.amountRemainingToPay.value > 0 ? 'Cancel' : (scope.isSimpleWorkflow ? 'Please wait...' : 'Completing Process');
        scope.dispatcherService.workflowReset(workflowName, WorkflowStepType.None);
        scope.dispatcherService.teaserReset();
        scope.log.info(`MachinePostSaleService. onToFlowBegin. isCashlessPayment: ${scope.isCashlessPayment}, paymentMethod: ${scope.saleService.order.paymentMethod} ${scope.paymentSession}`);

        scope.doAsync(() => {
          if (!scope.disableNavigation) {
            scope.router.navigateByUrl(scope.isSimpleWorkflow ? '/workflow-simple' : '/workflow');
          }
          scope.nextStep();
        }, 'onToFlowBegin');
      },
      onToPrintCreditCardReceipt(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.CreditCardReceiptPrinting);
        const creditCardReceipt = scope.paymentSession.cardTerminalReceipt;
        const task = new PrintTask(scope.saleService.order.id, PrintTaskType.CreditCardReceipt, creditCardReceipt);
        scope.log.info(`onToPrintCreditCardReceipt. ${task}`);
        scope.vuHttp.print(task);
      },
      onToWaitingForMoney(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.Wait);
        const toWaitingForMoneyTimeout = () =>
          scope.doAsync(() => scope.machine.toWaitingForMoneyTimeout(), 'onToWaitingForMoney');

        let waitingForMoneyTimeout = 0;
        if (scope.paymentSession.amountPaidIn.isPositive) {
          waitingForMoneyTimeout = 1500;
        }

        setTimeout(() => {
          if (scope.paymentSession.isCancelled) {
            scope.closeRemoteTransaction(false, 'Canceled.', toWaitingForMoneyTimeout);
          } else {
            toWaitingForMoneyTimeout();
          }
        }, waitingForMoneyTimeout);
      },
      onToWaitingForMoneyTimeout(): void {
        scope.dispatcherService.workflowLastStepStateSet();
        if (scope.paymentSession.isCancelled) {
          scope.doAsync(() => scope.machine.toCancelled(), 'onToWaitingForMoneyTimeout. toCancelled');
        } else {
          scope.doAsync(() => scope.machine.toCheckForChange(), 'onToWaitingForMoneyTimeout. toCheckForChange');
        }
      },
      onToCancelled(): void {
        scope.dispatcherService.workflowDeleteLastStep();
        if (scope.paymentSession.amountPaidIn.isZero) {
          scope.dispatcherService.isBackButtonEnabled = true;
          // Direct to previous screen
          scope.doAsync(() => scope.machine.toOff(), 'onToCancelled. amountPaid.isZero');
        } else {
          scope.doAsync(() => scope.machine.toReturningAmountPaid(),
            'onToCancelled. toReturningAmountPaid');
        }
      },
      onToReturningAmountPaid(): void {
        scope.dispatcherService.workflowReset('Cancel', WorkflowStepType.None);
        scope.dispatcherService.teaserReset();

        if (scope.isCashlessPayment) {
          scope.doAsync(
            () => scope.machine.toRevertingCashlessTransaction(),
            'onToReturningAmountPaid. isCashlessPayment'); // TODO: Refactore to return the money to the card.
          return;
        }

        scope.do(() => {
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountPaidIn
            .distract(scope.paymentSession.amountChangePaidOut);
          scope.dispatcherService.workflowAddStep(WorkflowStepType.PaymentReturn,
            ...scope.getPayoutArgs());

          const amountPaidOut = scope.paymentSession.amountPaidOut;
          const isCancelled = scope.paymentSession.isCancelled;

          if (!this.disableNavigation) {
            scope.isBackToHomeAfterSale = true;
          }

          scope.dispatcherService.cashDevicesStatePayOut(true);

          if (isCancelled && !amountPaidOut.isZero) {
            scope.doAsync(() => {
              scope.onMoneyChanged(amountPaidOut.negate());
            }, 'onToReturningAmountPaid. isCancelled && !amountPaidOut.isZero');
          }

          if (isCancelled) {
            // Initiate payout
            scope.closeRemoteTransaction(false, 'Canceled with incomming payment.');
          }
        }, 'onToReturningAmountPaid');
      },
      onToRevertingCashlessTransaction(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.RevertingCashlessTransaction);
        scope.vuHttp.revertCashlessTransaction(scope.saleService.order.paymentMethod)
          .then(() => {
            if (scope.saleService.order && scope.saleService.order.id) {
              scope.vuHttp.cancelOrder(scope.saleService.order);
            }
          }).catch(reason => {
            scope.onEventCashlessEvent(false);
          });
      },
      onToCheckForChange(): void {
        scope.dispatcherService.workflowDeleteLastStep();
        if (scope.paymentSession.isFloatingAmount || scope.paymentSession.amountToPay.isZero) {
          scope.dispatcherService.workflowAddStep(WorkflowStepType.AmountPaid, scope.paymentSession.amountPaidIn.toStringCurrencySign());
        } else {
          scope.dispatcherService.workflowAddStep(WorkflowStepType.AmountReached,
            scope.paymentSession.amountToPay.toStringCurrencySign(),
            scope.paymentSession.amountPaidIn.toStringCurrencySign());
        }
        scope.dispatcherService.workflowLastStepStateSet();
        if (!scope.paymentSession.amountToPay.isZero && scope.paymentSession.amountChange.isPositive && !scope.isCashlessPayment) {
          scope.doAsync(() => scope.machine.toReturningAmountChange(),
            'onToCheckForChange. toReturningAmountChange');
        } else {
          scope.doAsync(() => scope.nextStep(), 'onToCheckForChange. nextStep');
        }
      },
      onToReturningAmountChange(): void {
        scope.do(() => {
          scope.paymentSession.amountTempToPayOut = scope.paymentSession.amountChange;
          scope.dispatcherService.workflowAddStep(WorkflowStepType.ChangeReturn,
            ...scope.getPayoutArgs());
          scope.dispatcherService.cashDevicesStatePayOut(true);
          scope.doAsync(() => scope.checkAmountPaidOut(), 'onToReturningAmountChange');
        }, 'onToReturningAmountPaid');
      },
      onToSaveOrder(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.SaveOrder);
        const order = scope.saleService.order;

        scope.vuHttp.saveOrder(order).catch(reason => {
          scope.onOrderSaveResult(new OrderSaveResult(0,
            scope.saleService.order.uid, false, reason));
        });
      },
      onToPrintTickets(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.TicketPrinting);
        scope.vuHttp.print(new PrintTask(scope.saleService.order.id, PrintTaskType.Ticket))
          .catch((x) => {
            scope.onPrintTicketsResultReceived(false);
          });
      },
      onToPrintReceipt(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.ReceiptPrinting);
        scope.vuHttp.print(new PrintTask(scope.saleService.order.id, PrintTaskType.Receipt))
          .catch((x) => {
            scope.onPrintReceiptResultReceived(false);
          });
      },
      onToGateReadBarcode(event: any, ticket: Ticket): void {
        if (!ticket) {
          scope.log.error(`onToGateReadBarcode. Error, ticket is null`);
          scope.machine.toFlowEnd();
          return;
        }
        if (scope.gateSubscription) {
          scope.gateSubscription.unsubscribe();
        }
        scope.gateSubscription = scope.dispatcherService.onGateOpenComplete((message) => {
          scope.gateSubscription.unsubscribe();
          if (message?.info?.openSuccess) {
            scope.machine.toFlowEnd();
          } else {
            scope.cancelSessionAndReturnMoney(!scope.isCashlessPayment);
          }
        });

        if (scope.gateOpenDelayTimeoutMs) {
          scope.modalRef = scope.modalService.show(
            TimeoutSimpleModalComponent,
            {
              message: 'Time left before turn on',
              autoCloseTimeout: scope.gateOpenDelayTimeoutMs,
            },
            () => {
              scope.modalRef = null;
              scope._openGateByTicket(ticket);
            }
          );
        } else {
          scope._openGateByTicket(ticket);
        }
      },
      onToProducingRfidCard(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.CardDispenserProducing);
        if (scope.cardDispenserService.isAvailable) {
          scope.cardDispenserService.issueCard().catch(() => {
            scope.cancelSessionAndReturnMoney(false);
          });
        } else {
          scope.doAsync(
            () => {
              scope.cancelSessionAndReturnMoney(false);
            });
        }
      },
      onToReadRfidCard(): void {
        if (scope.requestRfidCardData) {
          scope.cardDispenserService.readRfidCardData(scope.requestRfidCardData);
        } else {
          scope.doAsync(
            () => {
              scope.nextStep();
            });
        }
      },
      onToWriteRfidCard(): void {
        if (scope.rfidCardData) {
          scope.cardDispenserService.writeRfidCardData(scope.rfidCardData);
        }
      },
      onToPrintRFIDCardReceipt(): void {
        if (scope.completeMessages && scope.completeMessages.length !== 0) {
          scope.dispatcherService.workflowAddStep(WorkflowStepType.ReceiptPrinting);
          scope.vuHttp.print(
            new PrintTask(
              scope.saleService.order.id,
              PrintTaskType.RFIDCardReceipt,
              JSON.stringify({
                receiptMessage: scope.completeMessages?.map(item => item ? scope.translateService.instant(item) : '').join(' '),
                writeCardDataCompleted: scope.writeCardDataCompleted,
              }),
            )
          );
        }

        scope.doAsync(
          () => {
            scope.nextStep();
          });
      },
      onToReleasingRfidCard(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.CardDispenserReleasing);
        if (scope.cardDispenserService.isAvailable) {
          scope.cardDispenserService.releaseCardCustomer();
        } else {
          scope.doAsync(
            () => {
              scope.cancelSessionAndReturnMoney(true);
            });
        }
      },
      onToWaitingForRfidCardRemoval(): void {
        scope.dispatcherService.workflowAddStep(WorkflowStepType.WaitingForRfidCardRemoval);
      },
      onToThanks(): void {
        scope.doAsync(() => {
          scope.dispatcherService.teaserAddItem(TeaserType.Thanks);
          scope.nextStep();
        }, 'onToThanks');
      },
      onToFlowEnd(): void {
        if (!scope.disableNavigation) {
          scope.dispatcherService.isBackButtonEnabled = true;
          scope.isBackToHomeAfterSale = true;
        }
        scope.closeRemoteTransaction(true, 'Commit transaction. FlowEnd');
        if (scope.disableNavigation || scope.externalPayment) {
          scope.doAsync(() => scope.machine.toOff(), 'onToFlowEnd. toOff');
        }
      },
    });
  }

  private get isSimpleWorkflow(): boolean {
    return this.configurationService.configuration.workflowPageType !== WorkflowPageType.Detailed;
  }

  private _openGateByTicket(ticket: Ticket): void {
    if (!ticket) {
      return;
    }
    let timeoutMs = 0;
    if (this.isFMCUOpen) {
      timeoutMs = ticket.accessTimeMinutes * 60 * 1000;
    }
    this.log.info(`openGateByTicket. barcode: ${ticket.code}}`);
    this.dispatcherService.gateOpen(ticket.code, '', OpenGateTypeEnum.Immediately, timeoutMs);
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    const state = this.state;
    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        if (state === 'flowEnd') {
          this.machine.toOff();
        }
        break;
      default:
        break;
    }
    return false;
  }

  machineStart(): void {
    if (this.isOff) {
      this.machine.toFlowBegin();
    }
  }

  private onMoneyChanged(money: Money): void {

    this.dispatcherService.onUserActivity();

    switch (this.state) {
      case 'returningAmountPaid':
        this.dispatcherService.workflowLastStepUpdate(... this.getPayoutArgs());
        if (this.paymentSession.isAmountPaidPaidOut) {
          this.dispatcherService.teaserAddItem(TeaserType.ReturnMoney);
          this.onRequestedPayoutComplete(() => this.machine.toFlowEnd());
        }
        break;
      case 'returningAmountChange':
        this.dispatcherService.workflowLastStepUpdate(... this.getPayoutArgs());
        const m = `totalPaidOut: ${this.paymentSession.amountPaidOut}. amountChange: ${this.paymentSession.amountChange}`;
        this.log.info(`MachinePostSaleService. ${m}`);
        this.checkAmountPaidOut();
        break;
      default:
        break;
    }
  }

  private onReturnChangeFinished(): void {

    this.dispatcherService.onUserActivity();

    const scope = this;
    setTimeout(() => {
      scope.onReturnChangeFinishedWithDelay();
    }, 1000); // to avoid the racing with the last eventMoneyChanged event
  }

  private onReturnChangeFinishedWithDelay(): void {
    if (this.state !== 'returningAmountChange') {
      this.log.info(`onReturnChangeFinished. Skip becase ${this.state} != 'returningAmountChange'`);
      return;
    }

    if (this.paymentSession.isAmountChangePaidOut) {
      this.log.info(`onReturnChangeFinished. Skip becase isAmountChangePaidOut`);
      return;
    }

    this.log.warning(`onReturnChangeFinished. Change not paid out!`);
    this.cancelSessionAndReturnMoney(false);
  }

  private checkAmountPaidOut(): void {
    if (this.paymentSession.isAmountChangePaidOut) {
      this.paymentSession.amountChangePaidOut = this.paymentSession.amountPaidOut;
      this.paymentSession.resetAmountTempPayout();

      this.dispatcherService.teaserAddItem(TeaserType.TakeChange);
      this.onRequestedPayoutComplete(() => this.nextStep());
    }
  }

  private onRequestedPayoutComplete(machineSwitch: () => void): void {
    this.dispatcherService.workflowLastStepStateSet();
    this.dispatcherService.cashDevicesStatePayOut(false);
    machineSwitch();
  }

  private onRfidCardRead(result: CardDispenserActionResult): void {
    if (this.state !== 'producingRfidCard') {
      return;
    }

    if (result.isFailed || !result.barcode) {
      this.cancelSessionAndReturnMoney(false);
    } else {
      this.dispatcherService.workflowLastStepStateSet();
      this.saleService.addRfidCardBarcodeInOrder(result.barcode);
      this.nextStep();
    }
  }

  private onResetOrderRfidCardAccessDataResult(result: OrderSaveResult): void {
    if (this.state !== 'writeRfidCard') {
      return;
    }

    this.completeMessages = [result.error];
    this.showAddAdditionalMessages(this.completeMessages, () => {
      this.nextStep();
    });
  }

  private onOrderSaveResult(result: OrderSaveResult): void {
    if (this.state !== 'saveOrder') {
      return;
    }
    this.dispatcherService.onUserActivity();

    result = OrderSaveResult.fromOther(result);
    this.saleService.order.id = result.orderId;
    const m = `MachinePostSaleService. onOrderSaveResult: ${result}`;
    if (result.isSuccess) {
      this.log.info(m);
    } else {
      this.log.error(m);
    }
    const order = this.saleService.order;
    if (result.isSuccess
      && result.orderUid === order.uid
      && result.orderId != null) {
      this.dispatcherService.workflowLastStepStateSet();
      if (this.isReadBarcodeAtGateAfterPayment) {
        const ticket = result.tickets != null &&
          result.tickets.length !== 0 ? result.tickets[0] : null;
        if (ticket == null) {
          this.log.warning('onOrderSaveResult. Order save result has no barcode.');
        }
        this.createdTicket = ticket;
      }
      order.isReceipt = order.isReceipt || result.forcePrintReceipt;

      this.rfidCardData = result.rfidCardData;
      this.completeMessages = result.warningMessages;
      if (this.rfidCardData) {
        this.nextStep();
      } else {
        this.showAddAdditionalMessages(this.completeMessages, () => {
          this.nextStep();
        });
      }
    } else if (this.externalPayment && this.paymentSession.amountToPay.isZero) {
      this.machine.toFlowEnd();
    } else {
      this.cancelSessionAndReturnMoney(false);
    }
  }

  private showAddAdditionalMessages(messages: string[], callbackFunc: () => void): void {
    if (messages?.length !== 0) {
      if (this.isSimpleWorkflow) {
        this.dispatcherService.workflowAddAdditionalMessages(messages);
      } else {
        this.messageModalService.showErrors(messages, callbackFunc);
        return;
      }
    }
    if (callbackFunc) {
      callbackFunc();
    }
  }

  private resetStepsData(): void {
    this.postSaleStepIndex = 0;
    this.createdTicket = null;
  }

  private nextStep(): void {
    const order = this.saleService.order;
    if (!order) {
      this.machine.toFlowEnd();
      return;
    }

    while (this.postSaleSteps.length > this.postSaleStepIndex) {
      const nextStep = this.postSaleSteps[this.postSaleStepIndex];
      this.postSaleStepIndex++;
      switch (nextStep) {
        case PostSaleTypeEnum.PrintCreditCardReceipt:
          if (this.isCashlessPayment && order.isCardTerminalReceipt) {
            this.machine.toPrintCreditCardReceipt();
            return;
          }
          break;
        case PostSaleTypeEnum.WaitingForMoney:
          if (!this.isCashlessPayment) {
            this.machine.toWaitingForMoney();
            return;
          }
          break;
        case PostSaleTypeEnum.SaveOrder:
          this.machine.toSaveOrder();
          return;
        case PostSaleTypeEnum.CheckExitRole:
          if (this.isExitRole) {
            this.machine.toFlowEnd();
            return;
          }
          break;
        case PostSaleTypeEnum.PrintTickets:
          if (order.hasTicketsToPrint && !this.isExitRole) {
            this.machine.toPrintTickets();
            return;
          }
          break;
        case PostSaleTypeEnum.PrintReceipt:
          if (order.isReceipt) {
            this.machine.toPrintReceipt();
            return;
          }
          break;
        case PostSaleTypeEnum.OpenGate:
          if (this.isReadBarcodeAtGateAfterPayment) {
            this.machine.toGateReadBarcode(this.createdTicket);
            return;
          }
          break;
        case PostSaleTypeEnum.ProducingRfidCard:
          if (order.hasRfidCard && !this.cardDispenserService.cardCode) {
            if (this.cardDispenserService.isAvailable) {
              this.machine.toProducingRfidCard();
              return;
            }
            this.log.warning('Step = ProducingRfidCard. Order has RFID but Card Dispenser is not available. Skipping.');
          }
          break;
        case PostSaleTypeEnum.ReadRfidCard:
          if (this.cardDispenserService.cardCode && this.requestRfidCardData) {
            if (this.cardDispenserService.isAvailable) {
              this.machine.toReadRfidCard();
              return;
            }
            this.log.warning('Step = ReadRfidCard. Order has RFID but Card Dispenser is not available. Skipping.');
          }
          break;
        case PostSaleTypeEnum.WriteRfidCard:
          if (this.rfidCardData) {
            if (this.cardDispenserService.isAvailable) {
              this.machine.toWriteRfidCard();
              return;
            }
            this.log.warning('Step = WriteRfidCard. Order has RFID but Card Dispenser is not available. Skipping.');
          }
          break;
        case PostSaleTypeEnum.PrintRFIDCardReceipt:
          if (this.completeMessages && this.completeMessages.length !== 0) {
            this.machine.toPrintRFIDCardReceipt();
            return;
          }
          break;
        case PostSaleTypeEnum.ReleasingRfidCard:
          if (order.hasRfidCard || this.cardDispenserService.isHasTemporaryCard) {
            if (this.cardDispenserService.isAvailable) {
              this.machine.toReleasingRfidCard();
              return;
            }
            this.log.warning('Step = ReleasingRfidCard. Order has RFID but Card Dispenser is not available. Skipping.');
          }
          break;
        case PostSaleTypeEnum.WaitingForRfidCardRemoval:
          if (order.hasRfidCard || this.cardDispenserService.isHasTemporaryCard) {
            if (this.cardDispenserService.isAvailable) {
              this.machine.toWaitingForRfidCardRemoval();
              return;
            }
            this.log.warning('Step = WaitingForRfidCardRemoval. Order has RFID but Card Dispenser is not available. Skipping.');
          }
          break;
        case PostSaleTypeEnum.Thanks:
          this.machine.toThanks();
          return;
      }
    }
    this.machine.toFlowEnd();
  }

  get requestRfidCardData(): RfidCardData {
    const requestRfidCardData = this.configurationService?.configuration?.originalConfigurationHelper?.getString('smart_card_data_to_read');
    if (!requestRfidCardData) {
      return null
    }
    var rfidCardData = new RfidCardData();
    rfidCardData.data = requestRfidCardData;
    return rfidCardData;
  }

  private onPrintTaskResultReceived(result: PrintTaskResult): void {
    this.dispatcherService.onUserActivity();

    const printTaskType = result.printTask.printTaskType;
    if (printTaskType === PrintTaskType.Ticket) {
      this.onPrintTicketsResultReceived(result.isSuccess);
    } else if (printTaskType === PrintTaskType.Receipt) {
      this.onPrintReceiptResultReceived(result.isSuccess);
    } else if (printTaskType === PrintTaskType.CreditCardReceipt) {
      this.onPrintCreditCardReceiptResultReceived(result.isSuccess);
    } else if (printTaskType === PrintTaskType.RFIDCardReceipt) {
      this.onPrintRFIDCardReceiptResultReceived(result.isSuccess);
    } else {
      this.log.error(`onPrintTaskResultReceived. Unsupported PrintTaskType: ${printTaskType}`);
    }
  }

  get isReadBarcodeAtGateAfterPayment(): boolean {
    return this.additionalPropertiesConfigurationService.isReadBarcodeAtGateAfterPayment
      || this.isFMCUOpen
      || this.isFMCUOpenWaitForEntrance;
  }

  get gateOpenDelayTimeoutMs(): number {
    return this.additionalPropertiesConfigurationService.openDelayTimeoutMs;
  }

  get isFMCUOpen(): boolean {
    return this.saleService?.order?.orderLines?.some(
      line => line?.product?.vuAutomaticActionType === VuAutomaticActionType.FMCU);
  }

  get isFMCUOpenWaitForEntrance(): boolean {
    return this.saleService?.order?.orderLines?.some(
      line => line?.product?.vuAutomaticActionType === VuAutomaticActionType.FMCUWaitForEntrance);
  }

  private onPrintTicketsResultReceived(result: boolean): void {
    if (this.state !== 'printTickets') {
      return;
    }

    this.dispatcherService.onUserActivity();

    this.log.info(`MachinePostSaleService. onPrintTicketsResultReceived. result: ${result}`);
    // result = false; // DEBUG REFUND !!!!
    if (result) {
      const type = this.saleService.order.orderLines.length > 1 ? TeaserType.TakeTickets : TeaserType.TakeTicket;
      this.dispatcherService.teaserAddItem(type);
      this.dispatcherService.workflowLastStepStateSet();
      this.nextStep();
    } else {
      this.cancelSessionAndReturnMoney(!this.isCashlessPayment);
    }
  }

  private onPrintReceiptResultReceived(result: boolean): void {
    if (this.state !== 'printReceipt') {
      return;
    }

    this.dispatcherService.onUserActivity();

    this.log.info(`MachinePostSaleService. onPrintReceiptResultReceived. result: ${result}`);
    this.dispatcherService.teaserAddItem(TeaserType.TakeReceipt);
    const step = result ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure;
    this.dispatcherService.workflowLastStepStateSet(step);
    this.nextStep();
  }

  private onRfidCardReadyToTake(result: CardDispenserActionResult): void {
    if (this.state !== 'releasingRfidCard') {
      return;
    }

    this.dispatcherService.onUserActivity();

    if (!result || result.isFailed) {
      this.dispatcherService.teaserReset();
      this.cancelSessionAndReturnMoney(true);
    } else {
      this.dispatcherService.workflowLastStepStateSet();
      this.dispatcherService.teaserAddItem(TeaserType.TakeCard);
      this.nextStep();
    }
  }

  private onRfidCardComplete(result: CardDispenserCompleteActionResult): void {
    if (this.state !== 'waitingForRfidCardRemoval') {
      return;
    }

    this.dispatcherService.onUserActivity();

    if (!result || result.captured || result.isFailed) {
      this.dispatcherService.teaserReset();
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
    } else {
      this.dispatcherService.workflowLastStepStateSet();
    }

    this.nextStep();
  }

  private onReadCardDataCompleted(result: CardDispenserCompleteActionResult): void {
    if (this.state !== 'readRfidCard') {
      return;
    }

    const readCardDataCompleted = result && !result.isFailed;
    if (readCardDataCompleted) {
      this.saleService.order.updateProperties('responseRfidCardData', result.cardJsonData);
    }

    this.nextStep();
  }

  private onWriteCardDataCompleted(result: CardDispenserCompleteActionResult): void {
    if (this.state !== 'writeRfidCard') {
      return;
    }
    this.writeCardDataCompleted = result && !result.isFailed;

    if (!this.writeCardDataCompleted) {
      this.vuHttp.resetOrderRfidCardAccessData(this.saleService.order.id, result.barcode);
      return;
    }
    this.showAddAdditionalMessages(this.completeMessages, () => {
      this.nextStep();
    });
  }

  private onPrintRFIDCardReceiptResultReceived(result: boolean): void {
    if (
      this.state !== 'printRFIDCardReceipt'
      && this.state !== 'releasingRfidCard'
      && this.state !== 'waitingForRfidCardRemoval'
      && this.state !== 'thanks'
    ) {
      return;
    }

    this.log.info(`MachinePostSaleService. onPrintRFIDCardReceiptResultReceived. result: ${result}`);
    this.dispatcherService.teaserAddItem(TeaserType.TakeReceipt);
  }

  private onPrintCreditCardReceiptResultReceived(result: boolean): void {
    if (this.state !== 'printCreditCardReceipt') {
      return;
    }

    this.dispatcherService.onUserActivity();

    this.log.info(`MachinePostSaleService. onPrintCreditCardReceiptResultReceived. result: ${result}`);
    const step = result ? WorkflowStepState.CompleteSuccess : WorkflowStepState.CompleteFailure;
    this.dispatcherService.workflowLastStepStateSet(step);
    this.nextStep();
  }

  protected get timeoutTrackingStates(): string[] {
    if (this.isReadBarcodeAtGateAfterPayment) {
      return this.getsAllStatesExceptFor('off', 'gateWaitingForEnter');
    }
    return this.getsAllStatesExceptFor('off');
  }

  private getPayoutArgs(): string[] {
    try {
      const result = [
        this.paymentSession.amountTempToPayOut.toStringCurrencySign(),
        this.paymentSession.amountTempPaidOut.toStringCurrencySign()
      ];
      return result;
    } catch (e) {
      this.log.error(this.paymentSession);
    }
    return ['?', '?'];
  }

  closeRemoteTransaction(isToCommit: boolean, context: string, revertPaymentTransactionCallback: any = null): void {
    if (!isToCommit && !this.canRefund) {
      return;
    }

    this.saleService.remoteTransaction.closeRemoteTransaction(
      isToCommit,
      context,
      revertPaymentTransactionCallback == null ? null : revertPaymentTransactionCallback.bind(this)
    );
  }

  private onEventCashlessEvent(isSuccess: boolean): void {
    if (this.state !== 'revertingCashlessTransaction') {
      this.log.info(`onEventCashlessEvent. The machine is not in 'revertingCashlessTransaction' state. Ignore the event.`);
      return;
    }

    this.dispatcherService.onUserActivity();

    if (isSuccess) {
      this.dispatcherService.workflowLastStepStateSet();
    } else {
      this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);
    }
    this.machine.toFlowEnd();
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    if (machineName === this.machineName
      && this.isInState(
        'waitingForMoney',
        'returningAmountChange',
        'printTickets',
        'saveOrder',
        'producingRfidCard',
        'releasingRfidCard',
        'waitingForRfidCardRemoval',
        'writeRfidCard',
        'printReceipt',
        'gateReadBarcode',
        'printRFIDCardReceipt',
        'thanks',
      )) {
      const cancelOrder = this.orderExist;
      this.cancelSessionAndReturnMoney(cancelOrder);
      return;
    }
    if (machineName === this.machineName
      && !this.isInState(
        'flowEnd'
      )) {
      this.closeRemoteTransaction(false, 'timeout');
    }
    super.onMachineTimeoutModalCancel(machineName);
  }

  get orderExist(): boolean {
    return this.saleService.order && this.saleService.order.id > 0;
  }

  get isOrderSaving(): boolean {
    return this.state === 'saveOrder';
  }

  get canRefund(): boolean {
    if (!this.orderExist) {
      if (this.isOrderSaving && this.configurationService?.configuration?.refundPaymentAction === RefundPaymentActionEnum.WithoutRefund) {
        return false;
      }

      return true;
    }

    return this.configurationService.configuration == null ||
      this.configurationService.configuration.refundPaymentAction == null ||
      this.configurationService.configuration.refundPaymentAction === RefundPaymentActionEnum.WithRefund;
  }

  private cancelSessionAndReturnMoney(cancelOrder: boolean): void {
    if (!this.canRefund) {
      if (!this.orderExist && this.isOrderSaving) {
        if (this.saleService.order.hasRfidCard && this.cardDispenserService.canCaptureCard) {
          this.cardDispenserService.stopTransaction();
        }

        this.dispatcherService.workflowAddStep(WorkflowStepType.ErrorOccurred);
        this.machine.toFlowEnd();
        return;
      }

      this.nextStep();
      return;
    }

    this.paymentSession.isCancelled = true;
    this.dispatcherService.workflowLastStepStateSet(WorkflowStepState.CompleteFailure);

    if (cancelOrder) {
      this.vuHttp.cancelOrder(this.saleService.order);
    }

    if (this.saleService.order.hasRfidCard && this.cardDispenserService.canCaptureCard) {
      this.cardDispenserService.stopTransaction();
    }

    this.machine.toReturningAmountPaid();
  }

  protected getMachineInactivitySettings(state: string): MachineInactivitySettings {
    switch (state) {
      case 'flowEnd':
        return new MachineInactivitySettings(this.additionalPropertiesConfigurationService.timeoutPostSaleFlowEndSec * 1000, true);
      case 'printTickets':
        return new MachineInactivitySettings(160000, true);
      case 'returningAmountChange':
        return new MachineInactivitySettings(this.additionalPropertiesConfigurationService.timeoutReturnAmountSec * 1000, true);
      case 'gateReadBarcode':
        if (this.gateOpenDelayTimeoutMs && this.configurationService.configuration) {
          const timeout = this.configurationService.configuration.timeIntervalBeforeTimeoutModal + this.gateOpenDelayTimeoutMs;
          return new MachineInactivitySettings(timeout, true);
        }
        return super.getMachineInactivitySettings(state);
      default:
        return super.getMachineInactivitySettings(state);
    }
  }
}
