import { OnInit, OnDestroy, Input, EventEmitter, Output, Component } from '@angular/core';
import { MessageService, Message, MessageType } from '../../services/message.service';
import { LanguageService } from '../../services/language.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  template: ''
})
export abstract class DatapickerBaseControl implements OnInit, OnDestroy {

  @Input()
  placeholder: string;

  @Input()
  cssClass: string;

  @Input()
  minDate: Date;

  @Input()
  maxDate: Date;

  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  @Output()
  valueChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();

  private messageSubscription: Subscription;

  constructor(
    protected languageService: LanguageService,
    protected messageService: MessageService,
  ) {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.updateLocale();
  }

  ngOnInit(): void {
    this.messageSubscription = this.messageService.getSubscription()
      .pipe(filter((x: Message) => [MessageType.LanguageChanged].indexOf(x.messageType) > -1))
      .subscribe(message => this.onMessage(message));
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }

  private onMessage(message: Message): void {
    if (message.messageType === MessageType.LanguageChanged) {
      this.updateLocale();
    }
  }

  updateLocale(): void {
    const locale = this.languageService.getLanguage();

    const europeanDateFormatLocales = [
      'de-DE',
      'cs-CZ',
      'uk-UA',
      'es-ES',
      'it-IT',
      'bg-BG',
      'sr-RS',
      'hr-HR',
    ];

    if (europeanDateFormatLocales.includes(locale)) {
      this.dpConfig.dateInputFormat = 'DD.MM.YYYY';
      this.dpConfig.rangeInputFormat = 'DD.MM.YYYY';
    } else {
      this.dpConfig.dateInputFormat = 'L';
      this.dpConfig.rangeInputFormat = 'L';
    }
  }

  onBsValueChange(value: Date[]): void {
    this.valueChange.emit(value);
  }
}
