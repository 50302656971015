import { Component } from '@angular/core';
import { BaseModalComponent } from 'src/app/components/general/base/base-modal-component';
import { PaymentMethod } from 'src/app/lib/payment/payment-method';

@Component({
  selector: 'app-payment-dialog-modal',
  templateUrl: './payment-dialog-component.component.html',
  styleUrls: ['./payment-dialog-component.component.css']
})

export class PaymentDialogModalComponent extends BaseModalComponent {

  onPaymentClick(paymentMethod: PaymentMethod): void {
    if (this.data && this.data.selectPaymentMethod) {
      this.data.selectPaymentMethod(paymentMethod);
    }
    this.close(paymentMethod);
  }

}
